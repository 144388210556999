<template>
    <div>
        <b-card class="m-5 p-3">
            <b-card-title>
                <h3>Orders</h3>
            </b-card-title>
                 <div class="col-md-4 col-sm-12 p-0 vue-select-dropdown mb-3">
                            <label>Filter By Status</label>
<b-form-select name="Status" v-model="selected" label="Status" @change="changeValue()" :options="choices.status1" ></b-form-select>

                            </div>
<div class="responsive-table">
            <vue-table :data="data.order_items" :fields="fields" :url="url" ref="table">
                <template #image="{rowData}">
                    <img :src="rowData.icon_path" style="max-width: 50px"
                         class="rounded-circle" alt="">
                </template>
                <template #action="{rowData}">
                    <b-button-group>
                        <b-button size="sm" variant="warning" @click="viewItem(rowData)">Details</b-button>
                    </b-button-group>
                </template>
            </vue-table>
</div>
        </b-card>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'secure-axios';
import urls from '@data/urls';

export default {
    name: 'Orders',
    components: {},
    data () {
        return {
            status: 'all',
            selected: 'all',
            url: urls.shopOrder.list + '?id=' + this.$route.params.id + '&status=' + status,
            data: [],
            choices: {
                status1: [
                    { value: 'all', text: 'All' },
                    { value: 'pending', text: 'Pending' },
                    { value: 'delivered', text: 'Delivered' },
                    { value: 'hold', text: 'hold' }
                ]
            },
            fields: [
                {
                    name: 'id',
                    title: 'Order ID',
                    sortField: 'id'
                },
                {
                    name: 'user_id',
                    title: 'user id',
                    sortField: ''
                },
                {
                    name: 'total_quantity',
                    title: 'Total Quantity',
                    sortField: ''
                },
                {
                    name: 'total_amount',
                    title: 'Total amount',
                    sortField: ''
                },
                {
                    name: 'status',
                    title: 'Status',
                    sortField: ''
                },
                {
                    name: '__slot:action',
                    title: 'Action'
                }
            ]
        };
    },

    methods: {

        setData (response) {
            this.$refs.table.refreshTable();
        },
        async loadData () {
            const response = await axios.get(urls.shopOrder.list + '?id=' + this.$route.params.id + '&status=all');
            this.setData(response.data.data);
        },
        async deleteItem (rowData) {
            const confirm = await this.$bvModal.msgBoxConfirm('Are you sure ?');
            if (confirm) {
                const params = { id: rowData.id };
                const response = await axios.form(urls.shop.delete, params);
                this.setData(response);
            }
        },
        async viewItem (rowData) {
            const id = rowData.id;
            this.$router.push({ path: '/shop-admin/order/view/' + id + '/details' });
        },
        async changeValue () {
            this.url = urls.shopOrder.list + '?id=' + this.$route.params.id + '&status=' + this.selected;
        }
    }
};

</script>
